import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Header  from '../components/Header';
import { collection, query, where, getDocs, } from "firebase/firestore";
import { firestore, auth } from '../components/Firebase';
import AssignmentModal from '../components/AssignmentModal'
import {
  Card,
  CardHeader,
  Typography,
  Chip,
  CardBody,
} from "@material-tailwind/react";
import { useAuthState } from "react-firebase-hooks/auth";


const TABLE_HEAD = ["Problem", "Title", "Status" ,"Difficulty"];
const TABLE_HEAD_ASSIGNMENT = ["Problem", "Title", "Status" ,"Difficulty", "Due Day"];
 
const Home = () => {
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [modalInfo, setModalInfo] = useState(null);
    const [tableRows, setTableRows] = useState([]);
    const [submissions, setSubmissions] = useState([]);
    const [assignments, setAssignments] = useState(null);
    const [assignmentSubmissions, setAssignmentSubmissions] = useState(null);
    const [user, loading, error] = useAuthState(auth);
    const [userInfo, setUserInfo] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const isUpdated = useRef(false);
    useEffect(() => {
      if (!loading && user){ setPageLoading(false)};
      if(user){
        const temp = getUser();
        getSubmissions();
      }
    }, [user, loading]);
    useEffect(() => {
      if(userInfo && userInfo.classes.length > 0){
        getAssignment();
        getAssignmentSubmissions();
      }
    },[userInfo])

    useEffect(() => {
      if (tableRows.length > 0 && submissions.length > 0 && !isUpdated.current) {
        console.log(tableRows, submissions)
        const updatedTableRows = tableRows.map(row => ({
          ...row,
          solved: submissions.includes(row.id) ? "Solved" : "Not Solved"
        }));
  
        setTableRows(updatedTableRows);
        isUpdated.current = true;
      }
    }, [tableRows, submissions]);

    const getSubmissions = async () =>{
      const q = query(collection(firestore, "submissions"), where('submitBy.id', '==', user.uid));

      const querySnapshot = await getDocs(q);
      let temp = []
      querySnapshot.forEach((doc) => {
        temp.push(doc.data().id);
      });      
      setSubmissions(temp)
    }

    const sortByIndex = (index) => {
      if(index == 3){
        const difficultyOrder = { "Easy": 1, "Medium": 2, "Hard": 3 };
        const sortedProblems = [...tableRows].sort((a, b) => {
          return difficultyOrder[a.difficulty] - difficultyOrder[b.difficulty];
        })
        setTableRows(sortedProblems);
      }else if(index == 2){
          const solvedOrder = { "Solved": 1, "Not Solved": 2};
          const sortedProblems = [...tableRows].sort((a, b) => {
            return solvedOrder[a.solved] - solvedOrder[b.solved];
          })
          setTableRows(sortedProblems);
        }else if(index == 1){
          const sortedProblems = [...tableRows].sort((a, b) => {
            const titleA = a.title.toUpperCase(); 
            const titleB = b.title.toUpperCase();
            return titleA.localeCompare(titleB);
          });
          setTableRows(sortedProblems);
        }
    };

    const sortAssByIndex = (index) => {
      if(index == 3){
        const difficultyOrder = { "Easy": 1, "Medium": 2, "Hard": 3 };
        const sortedProblems = [...assignments].sort((a, b) => {
          return difficultyOrder[a.difficulty] - difficultyOrder[b.difficulty];
        })
        setAssignments(sortedProblems);
      }else if (index === 2) {
        const sortedProblems = [...assignments].sort((a, b) => {
         
          const solvedA = a.id in assignmentSubmissions && assignmentSubmissions[a.id].status === "Accepted";
          const solvedB = b.id in assignmentSubmissions && assignmentSubmissions[b.id].status === "Accepted";
      
          // Sort solved problems first, then unsolved problems
          if (solvedA && !solvedB) return -1;
          if (!solvedA && solvedB) return 1;
          return 0;
        });
        setAssignments(sortedProblems);
      }else if(index == 1){
          const sortedProblems = [...assignments].sort((a, b) => {
            const titleA = a.title.toUpperCase(); 
            const titleB = b.title.toUpperCase();
            return titleA.localeCompare(titleB);
          });
          setAssignments(sortedProblems);
        }else if (index === 4) {
          const sortedProblems = [...assignments].sort((a, b) => {
            const dueDayA = new Date(a.dueDay);
            const dueDayB = new Date(b.dueDay);
            return dueDayA - dueDayB;
          });
          setAssignments(sortedProblems);
        }
        
    };

    const getUser = async () => {
        const q = query(collection(firestore, "users"), where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setUserInfo(doc.data())
        });
    }
    const getAssignmentSubmissions = async () =>{
      const q = query(collection(firestore, "assignmentSubmissions"), where('submitBy.id', '==', user.uid));

      const querySnapshot = await getDocs(q);
      let temp = {}
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        temp[docData.aid] = {status: docData.status, code: docData.code, comment: docData.comment, grade: docData.grade, language:docData.language};
      });      
      setAssignmentSubmissions(temp)
    }

    const setQuestions = (qs) => {
      let temp = []
      qs.forEach((doc) => {
        let t = {
          id: doc.id,
          title: doc.data().title,
          difficulty: doc.data().difficulty
        }
        temp.push(t);
      });      
      setTableRows(temp);

    }

    const getQuestion = async () => {
      const q = query(collection(firestore, "problems"));

      const querySnapshot = await getDocs(q);
      
      setQuestions(querySnapshot)
    }

    
    const getAssignment = async () => {
      const q = query(collection(firestore, "assignments"), where('classes', 'array-contains-any', userInfo.classes));

      const querySnapshot = await getDocs(q);
      let temp = []
      querySnapshot.forEach((doc) => {
        let t = {
          id: doc.id,
          title: doc.data().title,
          difficulty: doc.data().difficulty,
          dueDay: doc.data().dueDay
        }
        temp.push(t);
      });      
      setAssignments(temp)
    }

    useEffect(()=>{
        getQuestion();
    }, [])

    return(
      
      <>
        {showSubmitModal ? (
        <AssignmentModal
          setShowModal={setShowSubmitModal}
          id={modalInfo.id}
          title={modalInfo.title}
          info={modalInfo.info}
        />
        ) : null}
        <Header></Header>
      <div className="flex justify-start items-center flex-col">
            <h1 className="text-8xl text-center mb-10 text-white" style={{width:"45rem"}}>
              Cskphc
            </h1>
      </div>
        {userInfo ? (
          <div className="p-8">
            <div className="flex justify-start">            
              <div className="mr-10 text-white text-2xl border-2 rounded-xl p-5 bg-blue-600">{userInfo.displayName}</div>
              <div className="mr-10 text-white text-2xl border-2 rounded-xl p-5 bg-blue-600">{userInfo.email}</div>
            </div>
          </div>
        ) : ""}
      <hr></hr>
      {user?<div className="flex justify-around">
        <Card className="h-full w-[48%] shadow-none ">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="flex items-center p-3">
            <div>
              <Typography component={'span'} variant="h5" color="blue-gray">
                Problems list
              </Typography>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full table-auto text-left border-2 border-gray-2">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    onClick = {() => sortByIndex(index)}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      component={'span'}
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between  font-normal leading-none opacity-70"
                    >
                      {head}{" "}
                      {index !== 0 && (
                        <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {submissions?tableRows.map(({id, title, difficulty, solved}, index) => {
                const isLast = index === tableRows.length - 1;
                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                return (

                  <tr key={id} className="hover:bg-gray-100">
                    <td className={classes}>
                      <Link to={`/problems/?pid=${id}`}> 
                        <div className="flex items-center gap-3">

                            <Typography component={'span'} variant="small" color="blue-gray" className="font-normal">
                              {index + 1}
                            </Typography>

                        </div>
                      </Link>
                    </td>
                    <td className={classes}>
                      <Link to={`/problems/?pid=${id}`}> 
                        <div className="flex flex-col">
              
                          <Typography component={'span'} variant="small" color="blue-gray" className="font-normal">
                            {title}
                          </Typography>

                        </div>
                      </Link>
                    </td>
                    <td className={classes}>
                      <Link to={`/problems/?pid=${id}`}> 
                        <div className="flex flex-col">
                          <Typography component={'span'} variant="small" color="blue-gray" className="font-normal">
                            {solved == 'Solved'?<span className='text-green-500 font-bold'>Solved</span>:<span className='text-red-500 font-bold'>Not Solved</span>}
                          </Typography>

                        </div>
                      </Link>
                    </td>
                    <td className={classes}>
                      <Link to={`/problems/?pid=${id}`}> 
                        <div className="w-max">
                          <Chip
                            variant="ghost"
                            size="sm"
                            value={difficulty}
                            color={difficulty == "Easy" ? "green" : difficulty == "Medium" ? "amber" : "red"}
                          />
                        </div>
                      </Link>
                    </td>
                    
                  </tr>
                  
                );
              }):null}
            </tbody>
          </table>
        </CardBody>
        {/* <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography component={'span'} variant="small" color="blue-gray" className="font-normal">
            Page 1 of 10
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" color="blue-gray" size="sm">
              Previous
            </Button>
            <Button variant="outlined" color="blue-gray" size="sm">
              Next
            </Button>
          </div>
        </CardFooter> */}
      </Card>
      <Card className="h-full w-[48%] shadow-none ">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="flex items-center p-3">
            <div>
              <Typography component={'span'} variant="h5" color="blue-gray">
                Assignments list
              </Typography>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full table-auto text-left border-2 border-gray-2">
            <thead>
              <tr>
                {TABLE_HEAD_ASSIGNMENT.map((head, index) => (
                  <th
                    key={head}
                    onClick = {() => sortAssByIndex(index)}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      component={'span'}
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between  font-normal leading-none opacity-70"
                    >
                      {head}{" "}
                      {index !== 0 && (
                        <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {assignmentSubmissions?assignments.map(({ id, title, difficulty, dueDay}, index) => {
                const isLast = index === tableRows.length - 1;
                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                return (

                  <tr key={id} className="cursor-pointer hover:bg-gray-100" onClick={() =>{setShowSubmitModal(true);setModalInfo({id: id, title: title, info:assignmentSubmissions[id]})}}>
                    <td className={classes}>
                        <div className="flex items-center gap-3">

                            <Typography component={'span'} variant="small" color="blue-gray" className="font-normal">
                              {index + 1}
                            </Typography>

                        </div>
                    </td>
                    <td className={classes}>
                        <div className="flex flex-col">
              
                          <Typography component={'span'} variant="small" color="blue-gray" className="font-normal">
                            {title}
                          </Typography>

                        </div>
                    </td>
                    <td className={classes}>
                        <div className="flex flex-col">
              
                          <Typography component={'span'} variant="small" color="blue-gray" className="font-normal">
                            {id in assignmentSubmissions && assignmentSubmissions[id].status === "Accepted"?<span className='text-green-500 font-bold'>Solved</span>:<span className='text-red-500 font-bold'>Not Solved</span>}
                          </Typography>

                        </div>
                    </td>
                    <td className={classes}>
                        <div className="w-max">
                          <Chip
                            variant="ghost"
                            size="sm"
                            value={difficulty}
                            color={difficulty == "Easy" ? "green" : difficulty == "Medium" ? "amber" : "red"}
                          />
                        </div>
                    </td>
                    <td className={classes}>
                      {/* <Link to={`/assignments/?aid=${id}`}>  */}
                        <div className="w-max">
                          <Chip
                            variant="ghost"
                            size="sm"
                            value={dueDay}
                          />
                        </div>
                      {/* </Link> */}
                    </td>
                  </tr>
                  
                );
              }):null}
            </tbody>
          </table>
        </CardBody>
        {/* <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography component={'span'} variant="small" color="blue-gray" className="font-normal">
            Page 1 of 10
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" color="blue-gray" size="sm">
              Previous
            </Button>
            <Button variant="outlined" color="blue-gray" size="sm">
              Next
            </Button>
          </div>
        </CardFooter> */}
      </Card>
    </div>:<div className="text-center text-4xl py-20 bg-gray-300 font-semibold">--- Please Sign in ---</div>}
      {/* {question.map(q   => {
          return(<div>
              <Link to={`/problems/?pid=${q.fields.challenges_id}&user_id=1`}> {q.fields.challenges_id}{q.fields.title}</Link>
          </div>)
      })} */}
        </>
    )
}
export default Home;