import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Landing from "./pages/Landing";
import AddProblem from "./pages/AddProblem";
import AddAssignment from "./pages/AddAssignment";
import Assignment from "./pages/Assignment";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ClassReview from "./pages/ClassReview";
import Enrol from "./pages/Enrol";
import AddUserCSV from "./pages/AddUserCSV";
import "./index.css";
import { ThemeProvider } from "@material-tailwind/react";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/problems",
    element: <Landing />,
  },
  {
    path: "/assignments",
    element: <Assignment />,
  },
  {
    path: "/addProblem",
    element: <AddProblem />,
  },
  {
    path: "/addAssignment",
    element: <AddAssignment />,
  },
  {
    path: "/enrol",
    element: <Enrol />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/classReview",
    element: <ClassReview />,
  },
  {
    path: "/csv",
    element: <AddUserCSV />,
  },
  {
    path: "*",
    element: <div>not found</div>,
  },
]);


ReactDOM.createRoot(document.getElementById("root")).render(
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
);


