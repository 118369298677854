import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { firestore, auth } from '../components/Firebase'; 
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import Header  from '../components/Header';
function AddUserCSV() {
  const [jsonData, setJsonData] = useState([]);
  const [classDict, setClassDict] = useState({});
  const defaultPassword = "12345678";
  const [isLoading, setIsLoading] = useState(false); 
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "classes"));
        const newClassDict = {};

        querySnapshot.forEach((doc) => {
          // Assuming each document has a 'name' field
          newClassDict[doc.data().name] = doc.id;
        });

        setClassDict(newClassDict);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchClasses();
  }, []);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    parseCSV(file);
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      complete: (result) => {
        const filteredData = result.data.filter(row => 
          row && Object.keys(row).length > 0 && row.Email && row.Name 
        );
        setJsonData(filteredData);
        console.log(filteredData);
      },
      header: true,
      skipEmptyLines: true 
    });
  };

  const enrollStudents = async () => {
    setIsLoading(true);
    for (const student of jsonData) {
      try {

        const newUser = await createUserWithEmailAndPassword(auth, student.Email, defaultPassword);

        const classIds = student.Classes.split(' ').map(className => classDict[className]).filter(id => id);
        const studentData = {
          displayName: student.Name,
          classes: classIds,
          email: newUser.user.email,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          role: 'student'
        };
        await addDoc(collection(firestore, "users"), { uid: newUser.user.uid, ...studentData });
      } catch (error) {
        console.error("Error in student enrollment: ", error);
      }
    }
    setIsLoading(false);
    alert("Students enrolled successfully!");
  };

  const renderTableHeader = () => {
    return jsonData.length > 0 ? (
      <tr>
        {Object.keys(jsonData[0]).map((key, index) => (
          <th key={index} className="px-4 py-2 border bg-gray-200">{key}</th>
        ))}
      </tr>
    ) : null;
  };

  const renderTableData = () => {
    return jsonData.map((row, index) => (
      <tr key={index} className="bg-white border-b">
        {Object.values(row).map((val, idx) => (
          <td key={idx} className="border px-4 py-2 text-gray-700">{val}</td>
        ))}
      </tr>
    ));
  };

  return (
    <>
        <Header></Header>
        <div className="flex flex-col items-center  min-h-screen bg-gray-100 p-4">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <h1 className="block text-gray-700 text-xl font-bold mb-2">Create Students</h1>
            <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <button 
              onClick={enrollStudents}
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              disabled={isLoading}  // Disable button while loading
            >
              {isLoading ? 'Loading...' : 'Submit'}
            </button>
        </div>
        {jsonData.length > 0 && (
            <div className="overflow-x-auto">
            <table className="table-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <thead>{renderTableHeader()}</thead>
                <tbody>{renderTableData()}</tbody>
            </table>
            </div>
        )}
        </div>
        <div>
            <h1>Classes Dictionary</h1>
            <pre>{JSON.stringify(classDict, null, 2)}</pre>
        </div>
    </>
  );
}

export default AddUserCSV;