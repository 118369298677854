import React from "react";
import { useEffect, useState, Fragment } from "react";
import { classnames } from "../utils/general";
function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`${
          id === open ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    );
  }
const Submit = ({ testcases ,code, handleSubmit, submitOutputDetails, submitting, setShowModal}) => {
    const [open, setOpen] = useState(0);
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
      };
    const [tc, setTc] = useState(null);
    const switchTestcase = (i) =>{
        if (tc && tc === testcases[i]){
            setTc(null);
        } else{
            setTc(testcases[i]);
        }
    }

    async function copyTextToClipboard() {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(tc.Input);
        } else {
          return document.execCommand('copy', true, b64DecodeUnicode(tc.Input));
        }
    }

    function b64DecodeUnicode(str) {
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }
    const getTestcase = () =>{
        return(
            <div className = "flex flex-col">
                <div className= "flex justify-between font-bold text-xl mb-2">
                    <div>
                        Input
                    </div>
                    <div>
                        <button onClick={copyTextToClipboard}>
                            <img className ='h-5 inline-block mb-1' src={process.env.PUBLIC_URL  + `/copy.png`}></img>
                        </button>
                    </div>

                </div>
                <pre className="px-2 py-2 w-full h-24 bg-[#1e293b] rounded-md text-white font-normal text-sm overflow-y-auto mb-2">
                    {tc.Input}
                </pre>
                <div className= "font-bold text-xl mb-2">
                    Output
                </div>
                <pre className="px-2 py-2  w-full h-24 bg-[#1e293b] rounded-md text-white font-normal text-sm overflow-y-auto mb-2">
                    {tc.Visible? tc.Output: 'Hidden'}
                </pre>
                <div className= "font-bold text-xl bg-clip-text mb-2">
                    Status
                </div>
                <div>
                    {submitOutputDetails.length > 0 ? submitOutputDetails[testcases.indexOf(tc)].status?.description : "-"}
                </div>
            </div>
        )
    }
    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                <div className="relative">
                    <div style={{width:"70vh"}} className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                    <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">
                            Submit
                        </h3>
                        <button
                        className=""
                        onClick={() => setShowModal(false)}
                        >
                        <span className="text-black text-2xl">
                            X
                        </span>
                        </button>
                    </div>

                    <div className="relative flex-auto">
                        <p className="text-lg">
                            <div style={{height:"50vh"}} className='flex flex-col'>
                                <div className = "border-b-2 flex overflow-y-auto" style={{height:"62vh"}}>
                                    <div className="w-[20%] border-r-2 overflow-y-auto flex flex-col">
                                        {testcases.map((t, index) => {
                                            return (
                                                <button
                                                    key={index}
                                                    onClick={() => switchTestcase(index)}
                                                    className={classnames(
                                                    "pt-3 pb-3",
                                                    !code ? "opacity-50" : "",
                                                    index == testcases.indexOf(tc)? "bg-gray-100":"",
                                                    submitOutputDetails.length < 1?  "border-inherit" : submitOutputDetails[index].isCorrect? "text-green-600": "text-red-600",
                                                    )}
                                                > 

                                                    {t.Visible? <div>{'Testcase'}{index + 1}</div>:<div className="mr-2"><img className = "h-4 inline mb-1"src={process.env.PUBLIC_URL  + `/testcase_hidden.png`}></img>{'Testcase'}{index + 1}</div>}
                                                </button>
                                            )
                                        })}
                                    </div>
                                    <div className="w-[80%] pl-2 pr-2 pt-2">                                
                                        {(submitOutputDetails && tc) ? <>{getTestcase()}</> : null}
                                    </div>
                                </div>
                                <div style={{height:"8vh"}} className="flex">
                                    <button
                                        onClick={handleSubmit}
                                        disabled={!code || submitting} 
                                        className={classnames(
                                        "text-gray-800 ml-auto my-2 mr-3 font-bold text-lg py-1 px-4 bg-green-300 inline-flex items-center w-fit",
                                        !code ? "opacity-50" : ""
                                        )}
                                    >
                                        {submitting? "": <img className ='fill-current w-7 h-7 inline-block'src={process.env.PUBLIC_URL  + `/compile.png`}></img>}
                                        {submitting? 'Processing' : "Run"}
                                    </button>
                                </div>
                            </div>
                        </p>
                    </div>
                    {/*footer*/}
                    </div>
                </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
  );
};

export default Submit;