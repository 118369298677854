import React, {useState, useEffect} from 'react';
import { collection, query, where, getDocs } from "firebase/firestore";
import { auth, firestore} from '../components/Firebase';
import Header from '../components/Header'
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, setDoc } from "firebase/firestore";
const AddAssignment = () => {
    const [user, loading, error] = useAuthState(auth);
    const [classes, setClasses] = useState([]);
    const  [assignmentClasses, setAssignmentClasses] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [role, setRole] = useState(null);
    const [title, setTitle] = useState('');
    const [dueDay, setDueDay] = useState('');
    const [description, setDescription] = useState('');
    const [difficulty, setDifficulty] = useState('Easy');
    const [testcases, setTestcases] = useState([]);
    const getUser = async () => {
        const q = query(collection(firestore, "users"), where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setRole(doc.data().role)
        });
    }
    const addTestcase = () => {
        setTestcases(
            ts => [...ts, {
                Input: '',
                Output: '',
                Visible: 'false',
            }]
        )
    }
    const addClass = () => {
        setAssignmentClasses(
            ac => [...ac, classes[0].id]
        )
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newAssignment = doc(collection(firestore, "assignments"));
        try{
        const assignmentData = {
            title: title,
            description: description,
            difficulty: difficulty,
            testcases: testcases,
            classes: assignmentClasses,
            createdAt: Date.now(),
            updatedAt: Date.now(),
            dueDay: dueDay
        };
        await setDoc(newAssignment, assignmentData);
        alert("Success");
        }
        catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
            console.log(errorCode, errorMessage);
        }
    }

    const getClass = async () => {
        const q = query(collection(firestore, "classes"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setClasses(
                c => [...c, {
                    id:doc.id,
                    name:doc.data().name
                }]
            )
        });
    }

    useEffect(() => {
            getClass()
	}, []);

    useEffect(() => {
        if(user && role == null){
            getUser()
        }
		if (!loading && user){ setPageLoading(false)};
	}, [user, loading]);

	if (pageLoading){ return null};
    if (!(role == 'teacher' || role == 'admin')){ return null};
    

 
    return(
        <>
            <Header></Header>
            <main >        
                <section className="bg-gray-100 relative  w-screen flex">
                    <div className="text-white px-10 py-5 bg-gray-600 rounded w-[50%] mx-auto my-10">                                            
                        <h1 className="text-4xl mb-6 font-bold"> Create Assignment </h1>                       
                        <form>                                                                                            
                                <div className="mb-3">
                                    <label className="text-3xl" htmlFor="title">
                                        Title
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <input
                                        className="p-2 rounded text-xl w-full text-black"
                                        type="text"
                                        label="Title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}  
                                        required                                    
                                        placeholder="Title"                                
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="text-3xl" htmlFor="dueDay">
                                        Due day
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <input
                                        className="p-2 rounded text-xl w-full text-black"
                                        type="datetime-local"
                                        label="dueDay"
                                        value={dueDay}
                                        onChange={(e) => setDueDay(e.target.value)}  
                                        required                                    
                                        placeholder="dueDay"                                
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="text-3xl" htmlFor="description">
                                        Description
                                    </label>
                                </div>  
                                <div className="mb-3">
                                    <textarea
                                        className="p-2 rounded text-xl w-full text-black"
                                        type="text"
                                        label="Description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)} 
                                        required                                 
                                        placeholder="Description"              
                                    />
                                </div>  
                                <div className="mb-3">
                                    <label className="text-3xl" htmlFor="description">
                                        Difficulty
                                    </label>
                                </div>  
                                <div className="mb-6">
                                    <select 
                                        name="difficulty"
                                        className="p-2 rounded text-xl w-full text-black"
                                        required
                                        value={difficulty}
                                        onChange={(e) => setDifficulty(e.target.value)} 
                                    >
                                        <option value="Easy">Easy</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Hard">Hard</option>
                                    </select>
                                </div>  
                                <div className="mb-3">
                                    <label className="text-3xl" htmlFor="description">
                                    Testcases
                                    </label>
                                </div>  
                                {
                                    testcases.map((t, index) =>{
                                        return(
                                            <div key={index}>
                                                <div>
                                                    <label className="text-xl" htmlFor="description">
                                                        Testcase{index + 1}
                                                    </label>
                                                </div>  
                                                <div className="mb-3">
                                                    <label className="text-lg" htmlFor="description">
                                                        Input:
                                                    </label>
                                                </div> 
                                                <textarea
                                                    className="p-2 rounded text-xl w-full text-black"
                                                    type="text"
                                                    label=""
                                                    onChange={(e) => testcases[index].Input = e.target.value} 
                                                    required                                 
                                                    placeholder="Input"              
                                                />
                                               <div className="mb-1">
                                                    <label className="text-lg" htmlFor="output">
                                                        Output:
                                                    </label>
                                                </div> 
                                                <textarea
                                                    className="p-2 rounded text-xl w-full text-black"
                                                    type="text"
                                                    label=""
                                                    onChange={(e) => testcases[index].Output = e.target.value}
                                                    required                                 
                                                    placeholder="Output"              
                                                />
                                                <div className="mb-1">
                                                    <label className="text-lg" htmlFor="visible">
                                                        Visible:
                                                    </label>
                                                </div> 
                                                <div className="mb-3">
                                                    <select 
                                                        name="visible"
                                                        className="p-2 rounded text-xl w-full text-black"
                                                        onChange={(e) =>  testcases[index].Visible = e.target.value} 
                                                    >
                                                        <option value="false">false</option>
                                                        <option value="true">true</option>
                                                    </select>
                                                </div>  
                                            </div>
                                        )
                                    })
                                }
                                <div className="text-center text-2xl">
                                    <button type="button" onClick={addTestcase}> Add TestCase +</button>
                                </div>

                                <div className="mb-3">
                                    <label className="text-3xl" htmlFor="description">
                                        Classes
                                    </label>
                                </div>  
                                {
                                    assignmentClasses.map((t, index) =>{
                                        return(
                                            <div key={index}>
                                                <div className="mb-3">
                                                    <select 
                                                        name="classes"
                                                        className="p-2 rounded text-xl w-full text-black"
                                                        onChange={(e) =>  assignmentClasses[index] = e.target.value} 
                                                    >
                                                        {
                                                            classes.map((c, index) => {
                                                                return(
                                                                        <option key={index} value={c.id}>{c.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>  
                                            </div>
                                        )
                                    })
                                }
                                <div className="text-center text-2xl">
                                    <button type="button" onClick={addClass}> Add Class +</button>
                                </div>


                                <div className="mb-4">
                                    <button
                                        type="submit" 
                                        onClick={handleSubmit}  
                                        className="p-2 bg-green-500 rounded-xl"                       
                                    >  
                                        Submit                              
                                    </button>
                                </div>                                                                    
                            </form>                         
                    </div>
                </section>
            </main>
        </>
    )
}
 
export default AddAssignment;