import React, { useEffect, useState } from "react";
import CodeEditorWindow from "../components/CodeEditorWindow";
import axios from "axios";
import LanguagesDropdown from "../components/LanguagesDropdown";
import ThemeDropdown from "../components/ThemeDropdown";
import FontSizeDropdown from "../components/FontSizeDropdown";
import { languageOptions } from "../constants/languageOptions";
import { themeOptions } from "../constants/themeOptions";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import OutputWindow from "../components/OutputWindow";
import CustomInput from "../components/CustomInput";
import { useSearchParams } from 'react-router-dom';
import Splitter from 'm-react-splitters';
import 'm-react-splitters/lib/splitters.css';
import Submit from "../components/Submit";
import { addDoc,collection, getDocs, query, where} from "firebase/firestore";
import { firestore} from '../components/Firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../components/Firebase';
import Header from "../components/Header";
import {updateSubmission, showErrorToast, showSuccessToast, classnames, getQuestion, getUser, difficultyTable, b64EncodeUnicode} from "../utils/general";

const API_URL = "https://api.codex.jaagrav.in"
const Assignment = () => {
  
  const [fontSize, setFontSize] = useState(24);
  const [problem, setProblem] = useState(null);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const inputArea = document.querySelector('.inputarea');
  const [language, setLanguage] = useState(languageOptions[0]);
  const [theme, setTheme] = useState(themeOptions[1]);
  const [submitExpanded, setSubmitExpanded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({aid: -1});
  const aid = searchParams.get('aid');
  const [PythonDefault, setPythonDefault] = useState('');
  const [code, setCode] = useState('');
  const [customInput, setCustomInput] = useState("");
  const [compileOutputDetails, setCompileOutputDetails] = useState(null);
  const [submitOutputDetails, setSubmitOutputDetails] = useState([]);
  const [processing, setProcessing] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");
  const [submissionId, setSubmissionId] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
        if (user) {
            const fetchedUserInfo = await getUser(user);
            console.log(fetchedUserInfo)
            setUserInfo(fetchedUserInfo);
        }
    };

    fetchUserData();

    if (!loading && user) {
        setPageLoading(false);
    }

  }, [user, loading]);

  const storeSubmission = async (result, problem, user, userInfo, code, language, aid) => {
    try{
    const submissionData = {
        title: problem.title,
        difficulty: problem.difficulty,
        submitAt: Date.now(),
        submitBy: {id: user.uid, email: user.email, displayName: userInfo.displayName},
        aid: aid,
        comment: "",
        code: code,
        classes: userInfo.classes,
        grade: -1,
        status: result,
        language: language.name
    };
    const res = await addDoc(collection(firestore, "assignmentSubmissions"), submissionData);
    setSubmissionId(res.id)
    }
    catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
        console.log(errorCode, errorMessage);
    }
  }

  useEffect(() => {
    const fetchSubmissionId = async () => {
        if (userInfo) {
          const submissionsRef = collection(firestore, "assignmentSubmissions");
          const q = query(submissionsRef, 
            where("submitBy.id", "==", userInfo.uid),
            where("aid", "==", aid));
          try {
              const querySnapshot = await getDocs(q);
              if (querySnapshot.empty) {
                  console.log("No matching documents.");
                  return 'error';
              }
      
              querySnapshot.forEach(doc => {
                  setSubmissionId(doc.id)
                  return (doc.id)
              });
          } catch (error) {
              console.log("Error getting documents: ", error);
          }
        }
    };
    fetchSubmissionId();
  }, [userInfo, aid]);  

  const onSelectChange = (sl) => {
    setLanguage(sl);
  };
  const onThemeSelectChange = (t1) => {
    setTheme(t1);
  }
  const onFontSizeSelectChange = (fs) => {
    setFontSize(fs.value);
  };

  useEffect(() => {
    if (inputArea){
      inputArea.addEventListener("keydown", keyPress);
      return () => {
        inputArea.removeEventListener("keydown", keyPress);
      };
    }
  });

  const keyPress = (e) =>{
    if (e.key === "Enter" && e.ctrlKey === true ){
      handleCompile();
    }
  }

  function handleThemeChange(th) {
    const theme = th;

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }

  useEffect(() => {
    defineTheme("oceanic-next");
  }, []);

  useEffect(() => {
    const fetchQuestion = async () => {
      setProblem(await getQuestion(aid));
  };
  fetchQuestion();
  },[]);

  useEffect(() => {
    if (enterPress && ctrlPress) {
      handleCompile();
    }
  }, [ctrlPress, enterPress]);


  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        setPythonDefault('');
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };

  const handleSubmit = async () => {
    if (!problem || !problem.testcases || !problem.testcases.length) {
      console.error('No test cases found.');
      return;
    }
    setSubmitting(true);
    setSubmitOutputDetails([]);
    setProcessing(true);
    try {
      const results = [];
  
      for (let i = 0; i < problem.testcases.length; i++) {
        const testcase = problem.testcases[i];
        const formData = new URLSearchParams();
        const codeWithEncoding = `
import sys
import io
sys.stdout = io.TextIOWrapper(sys.stdout.buffer, encoding='utf-8')

${code}
    `
        formData.append('language', 'py');
        formData.append('code', codeWithEncoding); // Assuming `code` is defined elsewhere
        formData.append('input', testcase.Input || '');
  
        const options = {
          method: 'POST',
          url: API_URL,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: formData,
        };
  
        const response = await axios(options);
  
        // Check if the output matches the expected output
        const isCorrect = response.data.output.trim() === testcase.Output.trim(); // Trim to remove extra whitespace
  
        results.push({
          input: testcase.Input,
          expected_output: testcase.Output,
          actual_output: response.data.output,
          isCorrect: isCorrect,
        });
  
        console.log(`Request ${i + 1} result:`, results[i]);
      }
      await checkStatus_Submit(results)
      showSuccessToast(`Compiled Successfully!`);
    } catch (error) {
      console.error('Error:', error);
      // Handle error state
    } finally {
      setSubmitting(false);
      setProcessing(false);
    }
  };

  const handleCompile = () => {
    setCompileOutputDetails("");
    setProcessing(true);
    const formData = new URLSearchParams();
    const codeWithEncoding = `
import sys
import io
sys.stdout = io.TextIOWrapper(sys.stdout.buffer, encoding='utf-8')

${code}
    `
    formData.append('language', 'py');
    formData.append('code', codeWithEncoding);
    formData.append('input', customInput);

    const options = {
      method: "POST",
      url: API_URL,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      timeout: 5000,
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        handleResult(response.data);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        let status = err.response ? err.response.status : null;
  
        if (err.code === 'ECONNABORTED') {
          console.log('Request timed out');
          showErrorToast('Request timed out, check the input', 2000);
        } else if (status === 429) {
          console.log("too many requests", status);
          showErrorToast(`too many requests`, 2000);
        } else {
          showErrorToast(error.error || 'An error occurred', 2000);
        }
        
        setProcessing(false);
        console.log("catch block...", error);
      });
  };

  const handleResult = async (data) => {
    setProcessing(false);
    setSubmitting(false);
    setCompileOutputDetails(data);
    showSuccessToast(`Compiled Successfully!`);
     return;
  };

  const checkStatus_Submit = async (results) => {
    setSubmitOutputDetails(results);
    if(submissionId){
      updateSubmission(checkSubmitStatus(results), submissionId, problem, user, userInfo, code, language, aid)
    }
    else{
      storeSubmission(checkSubmitStatus(results), problem, user, userInfo, code, language, aid)
    }
    return;
  }

  const checkSubmitStatus = (results)=>{
    console.log(results)
    for (let i = 0; i < results.length; i++){
      if (!(results[i].isCorrect)){
        return 'Rejected'
      }
    }
    return 'Accepted'
  }

 

  const handleSubmitExpand = () =>{
    if (submitExpanded == true){
      setSubmitExpanded(false)
    }else{
      setSubmitExpanded(true)
    }
    console.log(submitExpanded)
  }

  if (pageLoading){ return null};
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showSubmitModal ? (
        <Submit
          setShowModal={setShowSubmitModal}
          handleExpand={handleSubmitExpand}
          testcases={problem.testcases} 
          code = {code}
          handleSubmit = {handleSubmit}
          submitOutputDetails = {submitOutputDetails}
          submitting = {submitting}
        />
        ) : null}
      <Header></Header>
      <Splitter
          position="vertical"
          primaryPaneMaxWidth="40%"
          primaryPaneMinWidth="0%"
          primaryPaneWidth= "20%"
      >    

          <div className="flex flex-col  border-r-2 overflow-y-auto">
            {problem? <div className="flex p-3 flex-col">
                <div className="rounded-md p-2 mb-2">
                    <div className="flex justify-between mb-4">
                        <h1 className="text-3xl font-bold">
                            Title: {problem.title}
                        </h1>
                    </div>
                    <hr className="mb-2"></hr>
                    <div>
                        <h6 className="text-2xl mb-2">
                          <span className="p-2 text-lg">
                            ID : {aid}
                          </span>
                        </h6>
                    </div>
                    <div>
                        <h6 className="text-2xl mb-2">
                          <span className={difficultyTable[problem.difficulty].color + ' ' + difficultyTable[problem.difficulty].textColor + ' p-1.5 rounded-xl text-lg'}>
                              {problem.difficulty} 
                          </span>
                        </h6>
                    </div>

                </div>
              <div className="p-2">
                <h1 className="text-2xl font-bold">
                  Description
                </h1>
                <div className="p-2">
                  <p className='text-lg p-4 bg-gray-100 rounded p-2'>
                    {problem.description}
                  </p>
                </div>
              </div>
              <div className="rounded-md p-2">
                <h1 className="text-2xl font-bold">
                    Example
                </h1>
                <div className="p-2">
                    <div className="rounded-md p-2 mb-3 bg-gray-100">
                    <div className="p-2">
                        <div className="flex mb-2">
                            <h1 className='text-lg font-bold mr-2 my-0'>Input:</h1>
                        </div>
                        <pre className="whitespace-pre-wrap">
                            <p className="pl-2 m-0 text-sm">
                              {problem.testcases[0].Input}
                            </p>
                        </pre>
                        <pre className="whitespace-pre-wrap">
                            <h1 className='text-lg font-bold m-0'>Output:</h1>
                            <p className="pl-2 m-0 text-sm">
                              {problem.testcases[0].Output}
                            </p>
                        </pre>
                    </div>
                    </div>
                
                
                </div>
              </div>

            </div> : ""}
          </div>
        
          <div className="flex pl-2 pt-4 flex-col">
            <div className = "block w-[100%] px-3 py-1">
              <div className = "flex justify-between" >
                <div className="mr-2 mb-2">
                  <LanguagesDropdown onSelectChange={onSelectChange} />
                </div>
                <div className="flex">
                  <div className="mt-auto mb-auto">
                      <button
                        onClick={handleCompile}
                        disabled={!code || processing}
                        style={{
                          borderLeft: "1px black solid",
                          borderTop: "1px black solid",
                          borderBottom: "1px black solid"
                        }}
                        className={classnames(
                          "bg-green-300 text-gray-800 font-bold py-1 px-3 rounded-tl-lg rounded-bl-lg inline-flex items-center",
                          !code || processing? "opacity-50" : ""
                        )}
                      >
                        {<img className ='w-4 h-4 mt-1 mb-1'src={process.env.PUBLIC_URL  + `/compile.png`}></img>}
                        <span className="ml-2 lg:block hidden">Run</span>  
                      </button>
                    </div>

                    <div className="mt-auto mb-auto">

                      <button
                        disabled={!code}
                        style={{
                          border: "1px black solid",
                        }}
                        data-tooltip-target="tooltip-default" 
                        className={classnames(
                          "bg-green-300 text-gray-800 font-bold py-1 px-3 rounded-tr-lg rounded-br-lg inline-flex items-center",
                          !code || processing? "opacity-50" : ""
                        )}
                        onClick={() => setShowSubmitModal(true)}
                      >
                        Submit <svg  viewBox="0 0 20 20" aria-hidden="true" focusable="false" className='w-4 h-4 mt-1 mb-1 ml-1'>
                          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z">
                          </path>
                        </svg>
                      </button>
                  </div>
                </div>
                <div className="flex">
                  <div className="mr-2 mb-2">
                    <FontSizeDropdown onSelectChange={onFontSizeSelectChange} />
                  </div>
                  <div className="mb-2">
                    <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
                  </div>
                </div>

              </div>
            
              <div className="items-end codeWindow ">
                    <CodeEditorWindow
                      fontSize = {fontSize}
                      defaultValue = {PythonDefault}
                      code={code}
                      onChange={onChange}
                      language={language?.value}
                      theme={theme.value}
                    />
              </div>

              <div className='flex'>

                    <div className="relative w-[30%] mr-3">
                      <CustomInput
                        code = {code}
                        processing={processing}
                        sendData={handleCompile}
                        customInput={customInput}
                        setCustomInput={setCustomInput}
                      />
                    </div>

                    <OutputWindow compileOutputDetails={compileOutputDetails} />

                </div>
            </div>
          </div>
      </Splitter>
  </>
  );
};
export default Assignment;
