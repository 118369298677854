import Header  from '../components/Header';
import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs,  doc, setDoc, updateDoc } from "firebase/firestore";
import { firestore, auth } from '../components/Firebase';
import { useAuthState } from "react-firebase-hooks/auth";
const ClassReview = () => {
    const [user, loading, error] = useAuthState(auth);
    const [userInfo, setUserInfo] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [classes, setClasses] = useState([]);
    const [classInfo, setClassInfo] = useState("");
    const [assignmentSubmissions, setAssignmentSubmissions] = useState({});
    const [assignments, setAssignments] = useState([]);
    const [assignmentID, setAssignmentID] = useState(null);
    const [students, setStudents] = useState([]);
    const [student, setStudent] = useState(null);

    const getUser = async () => {
        const q = query(collection(firestore, "users"), where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setUserInfo(doc.data())
        });
    }

    const getClasses = async () => {
        const q = query(collection(firestore, "classes"));

        const querySnapshot = await getDocs(q);  
        let temp = []
        querySnapshot.forEach((doc) => {
            let o = {
                id: doc.id,
                name: doc.data().name
            }
          temp.push(o);
        });      
        setClasses(temp)
    }

    const getAssignments = async (c) => {
        console.log(c)
        const q = query(collection(firestore, "assignments"), where('classes', 'array-contains', c));

        const querySnapshot = await getDocs(q);  
        let temp = []
        querySnapshot.forEach((doc) => {
            let o = {
                id: doc.id,
                title: doc.data().title,
                description: doc.data().description,
                dueDay: doc.data().dueDay
            }
          temp.push(o);
        });      
        setAssignments(temp)
    }

    const getAssignmentSubmissions = async (id) => {
        const q = query(collection(firestore, "assignmentSubmissions"), where('classes', 'array-contains', classInfo), where('aid', '==', id));

        const querySnapshot = await getDocs(q);  
        let temp = {}
        querySnapshot.forEach((doc) => {
          let d = doc.data();
          d.id = doc.id;
          temp[d.submitBy.id] = d
        });      
        setAssignmentSubmissions(temp)
    }

    const getStudents = async () => {
        const q = query(collection(firestore, "users"), where('classes', 'array-contains', classInfo));

        const querySnapshot = await getDocs(q);  
        let temp = []
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });      
        setStudents(temp)
    }

    const update = async () => {
        const d = doc(firestore, "assignmentSubmissions", assignmentSubmissions[student].id);
        await updateDoc(d, {
            comment: assignmentSubmissions[student].comment,
            grade: parseInt(assignmentSubmissions[student].grade),
        });
        alert("Success");
    }

    useEffect(() => {
      if (!loading && user){ setPageLoading(false)};
      if(user){
        getUser();
        getClasses();
      }
    }, [user, loading]);

    if(pageLoading || !userInfo){return null}
    if(userInfo.role != 'teacher'){return null;}
    return(
    <>
        <Header></Header>
        <div className="p-10 flex space-x-10">
            <div className="w-[55%]">
                <label className="block text-xl font-bold mb-2">
                    Class
                </label>
                <select
                    name="classes"
                    className="m-3 p-2 rounded border-2 border-black w-full"
                    required
                    value={classInfo}
                    onChange={(e) => {
                        setClassInfo(e.target.value);
                        getAssignments(e.target.value);
                        setStudents([]);
                        setStudent();
                        setAssignmentSubmissions([]);
                    }}
                >
                    <option key={-1} value={""}></option>
                    {classes.map((c, index) => (
                        <option key={index} value={c.id}>
                            {c.name}
                        </option>
                    ))}
                </select>
                <hr className="my-5"></hr>
                <h2 className="text-3xl mb-3">Assignment</h2>
                <div>
                    <table className="border-collapse w-full">
                        <thead>
                            <tr className="bg-gray-300">
                                <th className="p-2 text-base font-bold text-left">Title</th>
                                <th className="p-2 text-base font-bold text-left">Due Day</th>
                                <th className="p-2 text-base font-bold text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {assignments.map((a, index) => (
                                <tr key={index} className = {"border-2 rounded p-2 m-2 " + (a.id == assignmentID?"bg-gray-500":"")}>
                                    <td className="p-2 text-sm">{a.title}</td>
                                    <td className="p-2 text-sm">{a.dueDay}</td>
                                    <td className="p-2">
                                        <button
                                            onClick={() => {
                                                getAssignmentSubmissions(a.id);
                                                setAssignmentID(a.id);
                                                getStudents();
                                                setStudent();
                                            }}
                                            className="hover:scale-105 rounded-lg bg-green-500 text-white p-1 hover:bg-green-600 focus:outline-none"
                                        >
                                            Get Submissions
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <hr className="my-5"></hr>
                <h2 className="text-3xl mb-3">Student</h2>
                <div className="flex flex-wrap">
                    {students.map((s, index) => (
                        s.role === "student" ? (
                            <div
                                key={index}
                                className = {"hover:scale-105 border-2 rounded p-3 m-2 cursor-pointer text-sm " + (s.uid == student?"border-green-300 bg-gray-100":"")}
                                onClick={() => {
                                    setStudent(s.uid);
                                }}
                            >
                                <div className="font-bold">{s.displayName}</div>
                                <div>{s.email}</div>
                                {assignmentSubmissions[s.uid] ? (
                                    <>
                                        <div className="text-green-500">
                                            {assignmentSubmissions[s.uid].status === 'Accepted' ? 'Solved' : 'Solving'}
                                        </div>
                                        {assignmentSubmissions[s.uid].comment ? (
                                            <div className="text-green-500">Commented</div>
                                        ) : (
                                            <div className="text-red-500">Not Commented</div>
                                        )}
                                        {assignmentSubmissions[s.uid].grade > -1 ? (
                                            <div className="text-green-500">Graded</div>
                                        ) : (
                                            <div className="text-red-500">Not Graded</div>
                                        )}
                                    </>
                                ) : (
                                    <div className="text-red-500">Not Submitted</div>
                                )}
                            </div>
                        ) : (
                            ""
                        )
                    ))}
                </div>
            </div>
            <div className="fixed w-[40%] right-5 top-20 h-5/6 overflow-auto border-2 rounded">
                {assignmentSubmissions[student] ? (
                    <div className="p-5 m-2">
                        <label className="text-xl font-bold">Student:</label>
                        <div>{assignmentSubmissions[student].submitBy.displayName}</div>
                        <label className="text-xl font-bold">Email:</label>
                        <div>{assignmentSubmissions[student].submitBy.email}</div>
                        <label className="text-xl font-bold">Status:</label>
                        <div>
                            {assignmentSubmissions[student].status === 'Accepted' ? (
                                <span className="text-green-500">{assignmentSubmissions[student].status}</span>
                            ) : (
                                <span className='text-red-500'>{assignmentSubmissions[student].status}</span>
                            )}
                        </div>
                        <label className="text-xl font-bold">Language:</label>
                        <pre>{assignmentSubmissions[student].language}</pre>
                        <label className="text-xl font-bold">Code:</label>
                        <pre className='rounded-xl bg-gray-100 p-5 overflow-auto'>{assignmentSubmissions[student].code}</pre>
                        <label className="text-xl font-bold block">Comment:</label>
                        <textarea
                            defaultValue={assignmentSubmissions[student].comment}
                            onChange={(e) => {
                                assignmentSubmissions[student].comment = e.target.value;
                            }}
                            className="border-2 w-full p-2 my-2"
                        ></textarea>
                        <label className="text-xl font-bold block">Grade:</label>
                        <input
                            type="number"
                            className="border-2 w-full p-2 my-2"
                            defaultValue={assignmentSubmissions[student].grade}
                            onChange={(e) => {
                                assignmentSubmissions[student].grade = e.target.value;
                            }}
                        ></input>
                        <button
                            onClick={() => {
                                update();
                            }}
                            className="border-2 rounded-lg bg-green-500 text-white p-2 hover:bg-green-600 focus:outline-none"
                        >
                            Update
                        </button>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    </>
    )
}

export default ClassReview;