import React from "react";

const OutputWindow = ({ compileOutputDetails }) => {
  const getOutput = () => {
    
    if(compileOutputDetails.error){
      const lineNumberMatch = compileOutputDetails.error.match(/line (\d+)/);
        
        if (lineNumberMatch) {
          const originalLineNumber = parseInt(lineNumberMatch[1], 10);
          const adjustedLineNumber = originalLineNumber - 5;
          const adjustedErrorMessage = compileOutputDetails.error.replace(
            `line ${originalLineNumber}`,
            `line ${adjustedLineNumber}`
          );
      return (
          <pre className="px-2 py-1 font-normal text-lg text-red-500">
            {adjustedErrorMessage || ''}
          </pre>
        );
      }
    }
      return (
        <pre className="px-2 py-1 font-normal text-lg text-green-500">
          {compileOutputDetails.output || ''}
        </pre>
      );
    }
  return (
    <div  className="w-[70%]">
      <div  className="flex h-10">
        <h1 className="font-bold text-xl bg-clip-text  bg-gradient-to-r from-slate-900 to-slate-700 mb-auto mt-auto">
          Output
        </h1>
      </div>

      <div className="h-44 w-full bg-[#1e293b] rounded-md text-white font-normal text-lg overflow-y-auto">
        {compileOutputDetails ? <>{getOutput()}</> : null}
      </div>
    </div>
  );
};

export default OutputWindow;
